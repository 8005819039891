import React from "react"
import content from "../../content/call-to-action2.yaml"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Modal from "react-modal"
import { ButtonBase } from "@material-ui/core"
import loadingSpinner from "../styles/img/loader.svg"

Modal.setAppElement("#___gatsby")

const Loader = () => {

  return (
    <div style={{
      display : "flex",
      justifyContent : "center",
      alignItems : "center",
      backgroundColor : "#fff",
      width: "75px",
      height: "75px",
      textAlign: "center",
      margin: "30px auto",
    }}
    >
      <img src={loadingSpinner}
           alt="loader"
      />
    </div>
  )
}

const CallToAction2 = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);


  const openModal = () => {
    setIsOpen(true);
    setIsLoading(true);
    initializeHubspotForm();
    document.body.style.overflow = 'hidden';
  }

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  }

  const initializeHubspotForm = async () => {
    if("hbspt" in window){
      window.hbspt.forms.create({
        region: "eu1",
        portalId: "25577968",
        formId: "a9b182d6-0e3a-49c6-a471-cc341ba90151",
        target: "#form-container",
        onFormReady: () => setIsLoading(false)
      });
    }else{
      setTimeout(initializeHubspotForm, 500);
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "90%",
      height: "90%",
      maxWidth: "790px",
      maxHeight: "900px",
      padding: 30,
    },
    overlay:{
      zIndex: 999
    }
  }


  return (
    <section id="call-to-action2">
      <div className="row">
        <div className="one columns header-col">
          <h1><FontAwesomeIcon className="big-two" icon={faPaperPlane}/></h1>
        </div>
        <div className="seven columns">
          <h2>{content.title.text}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: content.body }}
          />
        </div>

        <div className="three columns action">
          <button onClick={() => openModal()} className="button">{content.button.label}</button>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Kontaktirajte nas"
        className="ReactModal__Content-custom"
      >
        <div className={"contact-title-box"}>
          <div className="spacer"/>
          <div id="contact-text" style={{textAlign: 'center', marginBottom: '40px', fontSize: '30px'}}>Kontaktirajte nas</div>
          <ButtonBase className="btn-close" onClick={() => closeModal()}>
            Zapri&nbsp;X
          </ButtonBase>
        </div>
        {isLoading && <Loader/>}
        <div id="form-container"></div>
      </Modal>

    </section>
  )
}

export default CallToAction2;