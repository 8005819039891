import React from "react"
import { Card } from "@material-ui/core"
import content from "../../content/featuresSection.yaml"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Features = () => {
  library.add(fas)

  return (
    <section id="pricing">
      <div className="row section-head">
        <h1>{content.title}</h1>
        <p>{content.body}</p>
      </div>

      <div className="row">
        <div className="pricing-tables bgrid-quarters s-bgrid-halves">
          {content.plans.map((plan, index) => (
            <div key={index} className="featureColumn">
              <div
                className="price-block"
                style={{ background: "none", border: "none", height: "410px" }}
              >
                <Card
                  className="card"
                  style={{
                    height: "100%",
                    padding: "10px",
                    lineHeight: "25px",
                  }}
                >
                  <h3 className="plan-title">
                    <i className="fa">
                      <FontAwesomeIcon className="two" icon={plan.fa} />
                    </i>
                    {plan.title}
                  </h3>
                  <span className="features" style={{ color: "black" }}>
                    {plan.features}
                  </span>
                </Card>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="buttons">
        <AnchorLink className="button learn-more" to="/kaj-vam-userisk-nudi">
          Preberi več
        </AnchorLink>
      </div>
    </section>
  )
}
export default Features;