import React from "react"
import content from "../../content/call-to-action.yaml"

const CallToAction = () => (
  <section id="call-to-action">
    <div className="row">
      <div className="two columns header-col">
        <img src={content.image} alt={"Ineor logo"} style={{width: "100px"}}/>
      </div>
      <div className="seven columns">
        <h2>{content.title.text}</h2>
        <p
          dangerouslySetInnerHTML={{ __html: content.body }}
        />
      </div>

      <div className="three columns action">
        <button onClick={() => window.open(content.url, "_blank", "noreferrer noopener")} className="button">{content.button.label}</button>
      </div>
    </div>
  </section>
)
export default CallToAction;