import React from "react"

import Layout from "../components/layout"

import MainTitle from "../components/mainTitle"
import WhatSection from "../components/whatSection"
import WhySection from "../components/whySection"
import CalltoAction from "../components/call-to-action"
import CalltoAction2 from "../components/call-to-action2"
import FeaturesSection from "../components/featuresSection"
// import Subscribe from "../components/subscribe"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import { Helmet } from "react-helmet"

const Index = () => {
  const { sections } = useSiteMetadata()
  const availableSections = {
    "mainTitle": MainTitle,
    // "subscribe": Subscribe,
    "whatSection": WhatSection,
    "whySection": WhySection,
    "call-to-action": CalltoAction,
    "call-to-action2": CalltoAction2,
    "featuresSection": FeaturesSection,
  }

  return(
    <>
      <Helmet>
        <script charSet="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/shell.js"/>
        <script>{`
        window.jQuery = window.jQuery || (() => ({
          // these are all methods required by HubSpot
          change: () => {},
          trigger: () => {},
        }));`}</script>
      </Helmet>

      <Layout>
        { sections.map((section, index) => {
          let Tagname = availableSections[section]
          return <React.Fragment key={index}><Tagname  /></React.Fragment>
        }) }
      </Layout>
    </>
  )
}
export default Index;